<template>
  <el-main id="projects-page">
    <div class="info-wrapper">
      <div class="date-picker-wrapper">
        <h2 class="title">Projetos</h2>
        <el-date-picker
          v-model="date"
          type="month"
          placeholder="Selecione um mês"
          format="MM/yyyy"
          :clearable="false"
          :picker-options="datePickerOptions"
          @change="newDatePeriod"
        >
        </el-date-picker>
      </div>
      <div class="search-wrapper">
        <el-select
          v-model="projectsFilter"
          id="projects-filter"
          @change="handleFilteredProjects"
        >
          <el-option
            v-for="item in filteredProjectsOptions"
            :key="`locale-project-${item.label}-${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="localeFilter"
          id="locale-filter"
          @change="handleLocale"
        >
          <el-option
            v-for="item in localeOptions"
            :key="`locale-project-${item.label}-${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          class="search-input"
          v-model="search"
          size="medium"
          placeholder="Pesquisar na tabela"
          clearable
          prefix-icon="el-icon-search"
        ></el-input>
      </div>
    </div>
    <div class="table-wrapper" v-if="!loading">
      <el-table
        size="small"
        class="projects-table"
        :data="getPageContent"
        v-loading="loadingTable"
        height="100%"
        max-height="100%"
        @sort-change="handleSortChange"
      >
        <el-table-column
          label="C. Custo"
          prop="costCenterCode"
          header-align="center"
          align="center"
          min-width="180"
          sortable
        />
        <el-table-column
          label="Nome"
          prop="projectName"
          show-overflow-tooltip
          header-align="left"
          min-width="180"
          sortable
          :formatter="handleTableFormatData"
        >
          <template slot-scope="scope">
            <div
              class="column-with-icon-left"
              @click="openDrawerRename(scope.row)"
            >
              <span>{{ scope.row.projectName }}</span>
              <i class="el-icon-edit"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Mês início"
          prop="startDate"
          show-overflow-tooltip
          :formatter="handleFormatTableDate"
          header-align="center"
          align="center"
          min-width="150"
          sortable
        />
        <el-table-column
          label="Mês fim"
          prop="endDate"
          show-overflow-tooltip
          :formatter="handleFormatTableDate"
          header-align="center"
          align="center"
          min-width="150"
          sortable
        />
        <el-table-column
          label="Gerente(s)"
          prop="manager"
          header-align="left"
          min-width="180"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            <div
              v-if="accessLevel === 'pmo'"
              class="column-with-icon-left"
              @click="handleOpenDrawerManagers(scope.row)"
            >
              <span>{{ scope.row.manager }}</span>
              <i class="el-icon-edit"></i>
            </div>
            <div v-else>
              <span>{{ scope.row.manager }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Saldo inicial"
          header-align="center"
          align="center"
          min-width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <div
              class="column-with-icon"
              @click="openDrawerBalance(scope.row)"
              v-if="scope.row?.generalInfo?.balance"
            >
              <span>{{
                handleFormatValue(scope.row?.generalInfo?.balance)
              }}</span>
              <i class="el-icon-edit"></i>
            </div>
            <div v-else>
              <el-button
                class="button-cost-tracking"
                @click="openDrawerBalance(scope.row)"
                :disabled="!scope.row.hasManagerSheet"
              >
                <el-tooltip
                  v-if="!scope.row.hasManagerSheet"
                  placement="top"
                  content="Planilha de GP precisa ser importada!"
                  :open-delay="500"
                >
                  <div>
                    <span>Relatar saldo</span> <i class="el-icon-edit"></i>
                  </div>
                </el-tooltip>
                <div v-else>
                  <span>Relatar saldo</span>
                  <i class="el-icon-edit"></i>
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Planilha Gerente"
          header-align="center"
          align="center"
          min-width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <div
              class="column-with-icon"
              @click="handleDeleteGPSheet(scope.row)"
              v-if="scope.row.hasManagerSheet"
            >
              <span> Excluir planilha GP </span>
              <i class="el-icon-close"></i>
            </div>
            <div
              class="column-with-icon"
              @click="openDrawerGP(scope.row)"
              v-else
            >
              <span> Importar planilha GP </span>
              <i class="el-icon-upload2"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          label="Detalhamento"
          header-align="center"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <div @click="openJustify(scope.row)" class="column-with-icon">
              <span>Ver detalhamento</span>
              <i class="el-icon-document"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="paginator-container">
        <el-pagination
          class="page-selector"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-sizes="getPageSizes"
          :page-size="getPageSize"
          :total="tableLength"
          layout="sizes, slot, prev, pager, next"
        >
          <el-row class="current-page">
            {{ getPaginatorText }}
          </el-row>
        </el-pagination>
      </div>
    </div>
    <div class="loading" v-else>
      <el-skeleton animated :rows="12"></el-skeleton>
    </div>
    <DrawerUpload
      :project="project"
      :visibility="isDrawerGPVisible"
      @close="handleCloseDrawerGP"
      @send-files="sendFiles"
    />
    <DrawerBalance
      :project="project"
      :visibility="isDrawerBalanceVisible"
      @close="handleCloseDrawerBalance"
      @onSave="saveInitialValue"
    />
    <DrawerRename
      :project="project"
      :visibility="isDrawerEditVisible"
      @close="handleCloseDrawerRename"
      @save="handleChangeProjectName"
    />
    <DrawerManagers
      :visibility="showDrawerManagers"
      :managersOptions="managersOptions"
      :project="project"
      @close="handleCloseDrawerManagers"
      @save="handleChangeManagers"
    />
    <el-dialog
      title="Inconsistência encontrada"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="dialog-body">
        <span class="text-description"
          >O sistema identificou uma inconsistência entre os dados dos meses
          passados e as informações da planilha GP.</span
        >
        <label class="dialog-body-bold-text">Opções de importação:</label>
        <el-checkbox-group v-model="checkList" class="checklist-options">
          <el-checkbox
            v-for="(item, index) in checklistOptions"
            :label="item.label"
            :key="index"
            :checked="item.isChecked"
            @change="(value) => handleCheckboxItem(value, index)"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" class="secondary-button"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          class="custom-button"
          @click="sendFilesByCheck"
        >
          Salvar
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { deleteManagerSheet, sendManagerSheet } from '@/service/integration'
import { getMonthDetails } from '@/service/monthlyDetails'
import { updateInitialValue } from '@/service/monthlyProjectDetails'
import { fetchPutCostCenterName } from '@/service/costCenters'
import { fetchGetUsersByAccessLevel } from '@/service/users'
import { updateManager } from '@/service/updateFinancialReportManager'
import { getLocations } from '@/service/locations'
import DrawerUpload from './Partials/DrawerUpload/DrawerUpload.vue'
import DrawerBalance from './Partials/DrawerBalance/DrawerBalance.vue'
import DrawerRename from './Partials/DrawerRename/DrawerRename.vue'
import DrawerManagers from './Partials/DrawerManagers/DrawerManagers.vue'

export default {
  name: 'Projects',
  data() {
    return {
      date: '',
      file: null,
      dialogVisible: false,
      checklistOptions: {
        pastBaseline: {
          label: 'Importar com baseline do passado',
          isChecked: null,
        },
        pastOutlook: {
          label: 'Importar com outlook do passado',
          isChecked: null,
        },
      },
      checkList: [],
      search: '',
      searchColumns: [
        { propName: 'manager', propType: 'String' },
        { propName: 'projectName', propType: 'String' },
        { propName: 'costCenterCode', propType: 'String' },
        { propName: 'endDate', propType: 'Date' },
        { propName: 'startDate', propType: 'Date' },
      ],
      loading: false,
      loadingTable: false,
      contentBuffer: [],
      contentData: [],
      managersOptions: [],
      page: 1,
      pageSize: this.$store.state.pageSize,
      pageInterval: this.$store.state.pageSize,
      tableLength: 0,
      project: {},
      showDrawerManagers: false,
      isDrawerGPVisible: false,
      isDrawerEditVisible: false,
      isDrawerBalanceVisible: false,
      localeFilter: '0',
      localeOptions: [
        {
          value: '0',
          label: 'Todas as Filiais',
        },
      ],
      filteredProjectsOptions: [
        {
          value: 'encouraged',
          label: 'Projetos incentivados',
        },
        {
          value: 'all',
          label: 'Todos os projetos',
        },
      ],
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      projectsFilter: 'Projetos incentivados',
      filteredOption: 'encouraged',
      accessLevel: localStorage.getItem('accessLevel'),
    }
  },
  components: {
    DrawerUpload,
    DrawerBalance,
    DrawerRename,
    DrawerManagers,
  },
  computed: {
    ...mapGetters(['getPageSize']),
    getPageSizes() {
      return this.handlePageSizes(this.pageInterval, this.tableLength)
    },
    getPageContent() {
      const pageContent = this.handlePageContent(
        this.search,
        this.searchColumns,
        this.contentBuffer
      )
      return this.handleGetPageContent(pageContent)
    },
    getPaginatorText() {
      const page = this.page
      const pageSize = this.getPageSize
      const dataSize = this.tableLength
      const start = (page - 1) * pageSize + (dataSize > 0 ? 1 : 0)
      const end =
        page * pageSize > dataSize ? dataSize : (page - 1) * pageSize + pageSize

      return `Mostrando de ${start} até ${end} de ${dataSize} itens.`
    },
  },
  methods: {
    ...mapGetters(['getSelectedMonth']),
    ...mapActions(['updateSelectedMonth']),
    handleCheckboxItem(value, index) {
      this.checklistOptions[index].isChecked = value
    },
    async saveInitialValue({ initialValue, project }) {
      let loading = this.$loading({
        lock: true,
        text: 'Atualizando saldo inicial...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        await updateInitialValue(project.generalInfoId, initialValue)
        project.initialValue = initialValue
        this.$message({
          showClose: true,
          type: 'success',
          message: 'Valor inicial atualizado!',
          duration: 5000,
        })
        this.isDrawerBalanceVisible = false
        this.handleProjectsData()
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    openDrawerRename(project) {
      this.project = project
      this.isDrawerEditVisible = true
    },
    openJustify(project) {
      this.$router.push({
        name: 'Details',
        params: { id: project.financialReportId, rubric: 'equip' },
      })
    },
    openDrawerGP(project) {
      this.isDrawerGPVisible = true
      this.project = project
    },
    openDrawerBalance(project) {
      this.isDrawerBalanceVisible = true
      this.project = project
    },
    handleCloseDrawerGP() {
      this.isDrawerGPVisible = false
      this.project = {}
    },
    handleCloseDrawerBalance() {
      this.isDrawerBalanceVisible = false
      this.project = {}
    },
    handleCloseDrawerRename() {
      this.isDrawerEditVisible = false
      this.project = {}
    },
    handleCloseDrawerManagers() {
      this.showDrawerManagers = false
      this.project = {}
    },
    handleOpenDrawerManagers(project) {
      this.showDrawerManagers = true
      this.project = { ...project }
    },
    async handleChangeManagers({ managers }) {
      let loading = this.$loading({
        lock: true,
        text: 'Salvando arquivos...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        await updateManager(this.project.financialReportId, managers)
        this.handleProjectsData()
        this.handleCloseDrawerManagers()
        this.$message({
          showClose: true,
          type: 'success',
          message: 'Gerente(s) atualizado com sucesso!',
          duration: 5000,
        })
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    setPage(page) {
      this.page = page
    },
    handleCurrentChange(newPage) {
      this.page = newPage
    },
    handleGetPageContent(data) {
      const pageContent = data.slice(
        this.getPageSize * this.page - this.getPageSize,
        this.getPageSize * this.page
      )
      this.tableLength = data.length
      return pageContent
    },
    handleChangeProjectName({ projectName }) {
      try {
        const data = fetchPutCostCenterName(
          this.project.costCenterId,
          projectName
        )
        if (data) {
          this.handleProjectsData()
          this.handleCloseDrawerRename()
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    handleSortChange({ column, order, prop }) {
      const orientation = {
        ascending: false,
        descending: true,
      }

      if (order) {
        let filtred = [...this.contentData]
        filtred.sort((a, b) => {
          if (a[prop] < b[prop]) {
            return -1
          }
          if (a[prop] > b[prop]) {
            return 1
          }
          return 0
        })
        if (orientation[order]) {
          filtred.reverse()
        }
        this.contentBuffer = filtred
      } else {
        this.contentBuffer = this.contentData
      }
    },
    handleLocale() {
      const filter = this.localeFilter
      if (filter && filter !== '0') {
        let newData = this.contentData.filter((item) => {
          let searchValue = filter.toLowerCase()
          const hasOnColumn = String(item.location)
            .toLowerCase()
            .includes(searchValue)
          if (hasOnColumn) {
            return item
          }
        })
        this.contentBuffer = newData
      } else {
        this.contentBuffer = this.contentData
      }
      this.search = ''
      this.page = 1
    },
    async handleFilteredProjects(option) {
      this.filteredOption = option
      await this.handleProjectsData()
      this.handleLocale()
    },
    handleFormatTableDate(row, column, cellValue, index) {
      return this.handleMonthAndYearFormatter(cellValue)
    },
    handleTableFormatData(row, column, cellValue, index) {
      return this.handleFormatData(cellValue)
    },
    handleDeleteGPSheet(project) {
      this.$confirm(
        'Tem certeza que deseja excluir essa planilha',
        'Atenção!',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
          type: '',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
        }
      )
        .then(() => {
          this.deleteGPSheet(project)
        })
        .catch(() => {})
    },
    async handleGetManagersOptions() {
      const { data } = await fetchGetUsersByAccessLevel('manager')
      this.managersOptions = data
    },
    async handleProjectsData() {
      try {
        this.loadingTable = true
        if (this.accessLevel === 'pmo') await this.handleGetManagersOptions()
        const { data, status } = await getMonthDetails(
          this.date,
          this.filteredOption
        )
        if (status === 204) {
          this.contentData = []
        } else {
          this.contentData = data
        }
        this.contentBuffer = this.contentData
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
        this.contentData = []
      } finally {
        this.loadingTable = false
        this.contentBuffer = this.contentData
      }
    },
    async getLocales() {
      try {
        const { data } = await getLocations()
        if (data) {
          const newOption = data.map((element) => {
            return {
              value: element,
              label: element,
            }
          })
          this.localeOptions = [...this.localeOptions, ...newOption]
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async newDatePeriod(newDate) {
      try {
        this.loadingTable = true
        this.date = newDate
        this.updateSelectedMonth(newDate)
        this.localeFilter = ''
        await this.handleProjectsData()
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        this.setPage(1)
        this.loadingTable = false
      }
    },
    async sendFilesByCheck() {
      let loading = this.$loading({
        lock: true,
        text: 'Salvando arquivos...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const pastBaseline = this.checklistOptions.pastBaseline.isChecked
        const pastOutlook = this.checklistOptions.pastOutlook.isChecked
        const { data } = await sendManagerSheet(
          this.file,
          this.date,
          this.project.financialReportId,
          pastBaseline,
          pastOutlook
        )
        if (data) {
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Planilha importada com sucesso!',
            duration: 5000,
          })
          await this.handleProjectsData()
        }
      } catch (error) {
        if (error.response.data.includes('divergente')) {
          this.dialogVisible = true
        } else {
          const hasIncorrectName =
            error.response.data.includes('O nome do arquivo') &&
            error.response.data.includes('está incorreto')
          const message = hasIncorrectName
            ? 'O nome do arquivo não segue o padão estabelecido!'
            : error.response.data
          this.$message({
            showClose: true,
            type: 'error',
            message: message,
            duration: 5000,
          })
          project.hasManagerSheet = false
        }
      } finally {
        this.isDrawerGPVisible = false
        this.dialogVisible = false
        loading.close()
      }
    },
    async sendFiles({ file, date, clearList, project }) {
      this.file = file
      this.project = project
      let loading = this.$loading({
        lock: true,
        text: 'Enviando arquivos...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const { data } = await sendManagerSheet(
          file,
          date,
          project.financialReportId
        )
        if (data) {
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Planilha importada com sucesso!',
            duration: 5000,
          })
          await this.handleProjectsData()
        }
      } catch (error) {
        if (error.response.data.includes('divergente')) {
          this.checklistOptions.pastBaseline.isChecked = false
          this.checklistOptions.pastOutlook.isChecked = false
          this.dialogVisible = true
        } else {
          const hasIncorrectName =
            error.response.data.includes('O nome do arquivo') &&
            error.response.data.includes('está incorreto')
          const message = hasIncorrectName
            ? 'O nome do arquivo não segue o padão estabelecido!'
            : error.response.data
          this.$message({
            showClose: true,
            type: 'error',
            message: message,
            duration: 5000,
          })
          project.hasManagerSheet = false
        }
      } finally {
        clearList()
        this.isDrawerGPVisible = false
        loading.close()
      }
    },
    async deleteGPSheet(project) {
      let loading = this.$loading({
        lock: true,
        text: 'Excluindo planilha...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const { data } = await deleteManagerSheet(project.financialReportId)
        if (data) {
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Planilha deletada com sucesso!',
            duration: 5000,
          })
          project.hasManagerSheet = false
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
        project.hasManagerSheet = true
      } finally {
        loading.close()
      }
    },
  },
  created() {
    this.date = this.getSelectedMonth()
    this.handleProjectsData()
    this.getLocales()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
