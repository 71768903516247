<template>
  <el-drawer
    :title="project.projectName"
    :visible.sync="visibility"
    :size="570"
    direction="rtl"
    :before-close="onClose"
  >
    <el-main class="upload-wrapper">
      <el-upload
        class="upload-demo"
        drag
        multiple
        action="/"
        accept=".xls,.xlsx, .xlsm"
        :auto-upload="false"
        :on-remove="handleRemove"
        :on-change="onChange"
        :file-list="fileList"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Arraste os arquivos aqui ou <em>clique para selecionar!</em>
        </div>
      </el-upload>

      <div class="files-box">
        <div
          :class="!item.details.error.status ? 'file' : 'file danger'"
          v-for="(item, index) in fileList"
          :key="`manager-error-${index}`"
        >
          <div>
            <span class="file-name">{{ item.raw.name }}</span>
            <span class="file-size">({{ item.details.size }}kb)</span>
            <div class="text-error" v-if="item.details.error.status">
              <i class="el-icon-warning"></i>
              <span>{{ item.details.error.message }}</span>
            </div>
          </div>
          <el-button
            class="file-close"
            icon="el-icon-close"
            @click="handleDelete(index)"
          ></el-button>
        </div>
      </div>

      <div class="footer-menu-item">
        <span
          :class="
            fileList.length === 1 ? 'file-count' : 'file-count text-error'
          "
        >
          {{ fileList.length }}/1 arquivos importados
        </span>
        <div>
          <el-button class="secondary-button" @click="cancelUpload"
            >Cancelar</el-button
          >
          <el-button
            class="custom-button"
            :disabled="hasErrorOnList"
            @click="sendFiles"
            >Salvar</el-button
          >
        </div>
      </div>
    </el-main>
  </el-drawer>
</template>

<script>
export default {
  name: 'DrawerUpload',
  data() {
    return {
      fileList: [],
    }
  },
  props: {
    project: Object,
    visibility: Boolean,
  },
  methods: {
    onClose() {
      this.$emit('close')
      this.fileList = []
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleDelete(position) {
      this.fileList.splice(position, 1)
    },
    normalizeFileName(name) {
      return name
        .trim()
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    },
    onChange(file) {
      const fileName = this.normalizeFileName(file.name)

      const hasOnFileList =
        this.fileList.length === 0
          ? false
          : this.fileList.some((element) => {
              const elementName = this.normalizeFileName(element.name)

              return fileName.includes(elementName)
            })

      if (hasOnFileList) {
        this.fileList.push({
          ...file,
          details: {
            size: (file.size / 1024).toFixed(),
            error: {
              name: file.name,
              status: true,
              message: 'Arquivo já existe na lista de importação.',
            },
          },
        })
      } else {
        this.fileList.push({
          ...file,
          details: {
            name: file.name,
            size: (file.size / 1024).toFixed(),
            error: {
              status: false,
              message: '',
            },
          },
        })
      }
    },
    async sendFiles() {
      const date = new Date()
      let file = this.fileList[0].raw

      this.$emit('send-files', {
        project: this.project,
        file: file,
        date: date,
        clearList: this.clearList,
      })
    },
    cancelUpload() {
      this.clearList()
      this.$emit('close')
    },
    clearList() {
      this.fileList = []
    },
  },
  computed: {
    getVisibility() {
      return this.visibility
    },
    hasErrorOnList() {
      if (
        this.fileList.length !== 1 ||
        this.fileList.some((e) => e.details.error.status)
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
