<template>
  <Drawer
    drawerName="Detalhamento do lançamento"
    :visibility="visibility"
    @close="onClose"
    @save="onSave"
  >
    <div class="drawer-content">
      <span>
        Edite o nome do centro de custo {{ project.costCenterCode }}.
      </span>
      <el-input
        v-model="projectName"
        placeholder="Informe um nome para o centro de custo"
      />
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'

export default {
  name: 'DrawerRename',

  data() {
    return {
      projectName: '',
    }
  },
  components: {
    Drawer,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    visibility: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    project(current) {
      this.projectName = current.projectName
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSave() {
      this.$emit('save', { projectName: this.projectName })
    },
  },
  computed: {},
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
