<template>
  <el-drawer
    :title="drawerTitle"
    :visible.sync="visibility"
    :size="570"
    direction="rtl"
    :before-close="onClose"
  >
    <div class="drawer-content">
      <label
        >Relate abaixo o saldo inicial do projeto
        {{ project.projectName }}.</label
      >
      <el-row class="input-container">
        <span>Valor a ser lançado:</span>
        <Money
          class="el-input my-margin"
          v-bind="money"
          v-model="initialValue"
        ></Money>
      </el-row>
      <div class="footer-menu-item">
        <div>
          <el-button class="secondary-button" @click="closeDrawer"
            >Cancelar</el-button
          >
          <el-button class="custom-button" @click="onSave">Salvar</el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { Money } from 'v-money'

export default {
  name: 'DrawerBalance',
  components: {
    Money,
  },
  data() {
    return {
      initialValue: 0,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
    }
  },
  props: {
    project: Object,
    visibility: Boolean,
    editMode: Boolean,
  },
  watch: {
    project() {
      this.initialValue = this.project?.generalInfo?.balance
    },
  },
  methods: {
    onClose() {
      this.initialValue = 0
      this.$emit('close')
      this.fileList = []
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleDelete(position) {
      this.fileList.splice(position, 1)
    },
    normalizeFileName(name) {
      return name
        .trim()
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    },
    closeDrawer() {
      this.clearList()
      this.$emit('close')
    },
    clearList() {
      this.fileList = []
    },
    onSave() {
      this.$emit('onSave', {
        initialValue: this.initialValue,
        project: this.project,
      })
    },
  },
  computed: {
    drawerTitle() {
      return `Relatar saldo inicial do projeto ${this.project?.projectName}`
    },
    getVisibility() {
      return this.visibility
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
