<template>
  <Drawer
    :drawerName="`Editar Gerentes do projeto ${project.costCenterCode}`"
    :visibility="visibility"
    @close="onClose"
    @save="onSave"
  >
    <div class="drawer-content">
      <span> Gerentes </span>
      <el-select v-model="managers" multiple>
        <el-option
          v-for="(manager, index) in managersOptions"
          :key="`manager-option-${index}`"
          :label="manager.name"
          :value="manager.id"
        >
        </el-option>
      </el-select>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'

export default {
  name: 'DrawerManagers',

  data() {
    return {
      managers: [],
    }
  },
  components: {
    Drawer,
  },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: () => {},
    },
    managersOptions: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    project(current) {
      this.projectName = current.projectName
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSave() {
      this.$emit('save', { managers: this.managers })
    },
  },
  watch: {
    project() {
      const currentManagersOptions = [...this.managersOptions]
      this.managers = (this.project?.manager || '')
        .split(', ')
        .reduce((acc, curr) => {
          const managerIndex = currentManagersOptions.findIndex(
            (option) => option.name === curr
          )
          if (managerIndex !== -1) {
            acc.push(currentManagersOptions[managerIndex].id)
            currentManagersOptions.splice(managerIndex, 1)
          }
          return acc
        }, [])
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
